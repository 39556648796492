import { merge } from 'remeda';

import { Cms_Color, Cms_Tenant } from '@lib/services/cms';
import { Tenant_ColorPalette } from '@lib/services/tenant';

const hex = (color?: Cms_Color) => {
  if (!color) return undefined;

  return `#${color.hex}`;
};

export const getColorTheme = (defaultColors: Tenant_ColorPalette, tenant?: Cms_Tenant) => {
  if (!tenant) {
    return defaultColors;
  }

  return merge(defaultColors, {
    primary: hex(tenant.colorPrimary),
    primaryDark: hex(tenant.colorPrimaryDark),
    primaryLight: hex(tenant.colorPrimaryLight)
  });
};

const tenantService = {
  getColorTheme
};

export { tenantService as tenant };
