'use client';

import React, { useCallback, useEffect } from 'react';

import { css, cva } from '@pt-group-fe/styled-system/css';
import { Box } from '@pt-group-fe/styled-system/jsx';

import { useIsMounted } from '@lib/hooks';
import { Loader } from '@lib/ui/loader';

export declare type CS_ChatMode = 'popup' | 'standalone';

export declare type CS_ColorMode = 'light' | 'dark';

interface CS_ConfigChat {
  mode?: CS_ChatMode;
  colorMode?: CS_ColorMode;
  logo?: string;
  hideStartMsg?: boolean;
  onStartNewChat?: () => void;
  style?: {
    colorPrimary?: string;
    colorPrimaryLight?: string;
    colorPrimaryDark?: string;
    colorBgGray?: string;
    colorDarkBlue?: string;
    colorDarkBlueLighter?: string;
  };
}

interface CS_Config {
  root: string;
  debug?: boolean;
  apiUrl?: string;
  chat?: CS_ConfigChat;
  auth?: {
    setToken: (token: string) => void;
  };
}

interface ConciergeConfig {
  config: CS_Config;
  jsUrl: string;
}

const loader = cva({
  base: {},
  variants: {
    isVisible: {
      false: {
        display: 'none'
      }
    }
  }
});

const Concierge: React.FC<ConciergeConfig> = ({ config, jsUrl }) => {
  const isMounted = useIsMounted();
  const [isScriptLoaded, setScriptLoaded] = React.useState(false);

  const init = useCallback(() => {
    const script = document.createElement('script');
    script.setAttribute('src', jsUrl || '');
    script.setAttribute('id', 'concierge-js');
    script.setAttribute('defer', '');
    script.onload = function () {
      setScriptLoaded(true);

      if (!window._CS_) {
        return;
      }

      window._CS_?.init({ ...config });
    };

    document.body.appendChild(script);
  }, [config, jsUrl]);

  useEffect(() => {
    if (!isMounted) return;
    if (window._CS_) {
      setScriptLoaded(true);
      window._CS_?.init({ ...config });

      return;
    }

    init();
  }, [config, init, isMounted]);

  if (!isMounted) return null;

  return (
    <Box
      w={10}
      h={10}
      position="fixed"
      bottom={{ base: 4, lg: 8 }}
      right={{ base: 4, lg: 8 }}
      zIndex={3000}
      id="kvant-ai-concierge"
      className={css({
        '& .cs_app--root': {
          '--cs-color-primary': '{colors.primary}!'
        },
        '& [data-cs-popover-content]': {
          padding: { base: 4, lg: '6!' },
          w: 'calc(100dvw - 14px)!'
        }
      })}
    >
      <Loader className={loader({ isVisible: !isScriptLoaded })} />
    </Box>
  );
};

export { Concierge };
